import {GDPRStatusEnum} from "../../enums/gdpr/gdpr-state.enum";

export class GdprConsentModel {
  status: GDPRStatusEnum
  newsConsent: boolean | null;
  eventsConsent: boolean | null;
  dotDigitalConsent: boolean | null;
  createdAt: Date;
  modifiedAt: Date;


  constructor(
    status: GDPRStatusEnum,
    newsConsent: boolean | null,
    eventsConsent: boolean | null,
    dotDigitalConsent: boolean | null,
    createdAt: Date,  // added createdAt property to track when consent was given
    modifiedAt: Date
  ) {
    this.status = status;
    this.newsConsent = newsConsent;
    this.eventsConsent = eventsConsent;
    this.dotDigitalConsent = dotDigitalConsent;
    this.createdAt = createdAt;
    this.modifiedAt = modifiedAt;
  }
}
