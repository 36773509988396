import {environment} from "../../../environments/environment";

export const SIGNATURE: string =`<table border="0" cellpadding="0" cellspacing="0" style="font-family: Arial, Helvetica, sans-serif;
 font-size: 10px; line-height: 17px;margin-top: 50px; font-weight: normal; mso-line-height-rule:exactly;
  -webkit-font-smoothing:antialiased; -webkit-text-size-adjust: 100%; -ms-text-size-adjust:100%; text-size-adjust:100%;" width="100%">
  <tbody>
    <tr>
      <td style="border-right:1px solid #0082bf;width:170px;mso-text-raise:0px;line-height:0px">
        <a href="https://www.atoz.lu/" target="_blank">
          <img height="auto" width="150" style="display: block; border: 0; -ms-interpolation-mode: bicubic; margin: 0 20px 0 0;
width:90%;max-width:150px;" src="${environment.clientUrl}assets/media/logos/ATOZ-Logo-Tax-adviser.png">
        </a>
      </td>
      <td style="color:#53565A;">
        <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px;margin: 5px 0 10px 20px;">
          <strong>$fullName</strong>
          <span style="color:#0082bf">&#9632;</span> $jobTitle
        </p>
        <p style="font-family: Arial, Helvetica, sans-serif; font-size: 12px;margin: 5px 20px;">
          $officePhone
  <a href="mailto:$contactOwnerEmail" target="_blank" style="text-decoration: none;color:#53565A;margin-left: 15px;"> $contactOwnerEmail</a>
        </p>
        <p style="font-family: Arial, Helvetica, sans-serif; font-size: 12px;margin: 5px 20px;">
          $privatePhone
  <a href="https://www.atoz.lu/" target="_blank" style="text-decoration: none;color:#0082bf;margin-left: 15px;">www.atoz.lu</a>
        </p>
        <p style="text-decoration: none;margin: 10px 0 0 20px;line-height:25px;">
          <a style="text-decoration: none; mso-line-height-rule:exactly;" target="_blank" href="https://www.linkedin.com/company/atoz">
            <img height="20" style="display: inline-block; border: 0; -ms-interpolation-mode: bicubic; margin-right : 4px;" width="20" src="${environment.clientUrl}assets/media/logos/linkedin.png">
          </a>&nbsp;&nbsp; <img style="display: inline-block; border: 0; -ms-interpolation-mode: bicubic; margin-right : 4px;" src="${environment.clientUrl}assets/media/logos/Taxand.png">&nbsp;&nbsp;
        </p>
      </td>
    </tr>
  </tbody>
</table>
<table border="0" cellpadding="0" cellspacing="0" style="font-family: Arial, Helvetica, sans-serif; font-size: 10px; line-height: 17px; font-weight: normal; mso-line-height-rule:exactly; -webkit-font-smoothing:antialiased; -webkit-text-size-adjust: 100%; -ms-text-size-adjust:100%; text-size-adjust:100%;" width="100%">
  <tbody>
    <tr>
      <td style="width:100%;max-width:600px;">
        <p style="font-family: Arial, Helvetica, sans-serif; font-size: 10px; margin-bottom: 8px; mso-line-height-rule:exactly; -webkit-font-smoothing:antialiased; -webkit-text-size-adjust: 100%; -ms-text-size-adjust:100%; text-size-adjust:100%;color: #8A8D8F;">&nbsp; <br> This email (including any attachments) may contain legally privileged and/or confidential information and is intended only for the designated recipient(s). If you have received it by mistake, please inform us by an email reply, remove it from your computer system and do not use, copy or disclose the information contained in this message or in any attachment. Email transmission cannot be guaranteed to be secure or error-free. Therefore, the sender cannot certify that this information is complete and/or accurate and it should not be relied upon as such. </p>
        <p style="font-family: Arial, Helvetica, sans-serif; font-size: 10px; margin-bottom: 8px; mso-line-height-rule:exactly; -webkit-font-smoothing:antialiased; -webkit-text-size-adjust: 100%; -ms-text-size-adjust:100%; text-size-adjust:100%;color: #4E901E;">
          <img height="16" style="display: inline-block; border: 0; -ms-interpolation-mode: bicubic; margin-right : 4px;" width="16" src="${environment.clientUrl}assets/media/logos/tree.png">Please consider the environment before printing this email.
        </p>
      </td>
    </tr>
  </tbody>
</table>`;
